import { useDateFormat } from "@vueuse/core";

interface IUrl {
  publishedOn: string;
  slug: string;
  [key: string]: any;
}

export const mapToPostUrl = (cms: IUrl): string => {
  const date = useDateFormat(cms.publishedOn, "YYYY/MM/DD");
  return `/news/${date.value}/${cms.slug}`;
};
