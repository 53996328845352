import { IAsidePostsResponse } from "~/types/contentful/responses/";
import { IAsidePostsComponent } from "~/types/components";
import { mapToPostUrl } from "../models";
import { mapAssetToFrontiersImageByMedia } from ".";
import { AsidePostImageQueries } from "~/const/ImageQueriesByMedia";

const mapToAsidePosts = (
  posts: IAsidePostsResponse[],
  lazyLoadingAttr: boolean
): IAsidePostsComponent[] => {
  return posts.map((cms: IAsidePostsResponse) => {
    return {
      title: cms.title,
      url: mapToPostUrl(cms),
      frontiersImage: mapAssetToFrontiersImageByMedia(
        cms.featuredMedia,
        AsidePostImageQueries,
        lazyLoadingAttr
      )
    } as IAsidePostsComponent;
  });
};

export { mapToAsidePosts };
